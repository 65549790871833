<template>
  <div class="page_content_wrap">
    <div class="risk_yes_no_wrap py-5">
      <div class="container">
        <div class="risk_yes_no_box" v-if="getUser">
          <h5 class="text-primary mb-3">
            Thanks, {{ getUser.name }}!
            <span class="font-size-lg">😀</span>
          </h5>
          <h2 class="text-dark mb-2">
            Now let's look at some of the most common risks that could
            negatively impact your retirement.
          </h2>
          <p class="text-dark mb-2 font-size-lg">
            We’ll present you with a series of cards with some of the most
            common retirement risks. Prioritize them based on what matters to
            you the most.
          </p>
          <div class="text-center risk_yes_no_box_img">
            <img
              src="../../assets/images/risk_yes_no.png"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="btn_continue_wrap mt-3">
            <button @click="nextPage" class="btn btn-primary btn-lg btn-block">
              GOT IT!<i class="fas fa-arrow-right pl-1"></i>
            </button>
          </div>
        </div>
        <div class="loader" v-else></div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Screen5",
  computed: {
    ...mapGetters({
      getUser: "getUser"
    }),
  },
  methods: {
    nextPage() {
      let userObj = {
        riskLink: "risk.slider",
      };
      this.updateProgress({ step: 1, data: userObj });
      this.$router.push({ name: "risk.slider" });
    },
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
  },
};
</script>
